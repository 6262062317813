<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import { formatDate } from "~/lib/date-time";
import nodeRenderers from "~/lib/node-renderers";
import type { TypeShareholderResolution } from "~/types/contentful";

defineProps<{
  resolution: TypeShareholderResolution<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
}>();
</script>

<template>
  <div class="shareholder-resolution article">
    <Container
      v-if="resolution.fields.title"
      class="relative bg-level-standard lg:border-l-[16px] lg:border-l-dark-blue-grey-two lg:before:absolute lg:before:left-2 lg:before:top-0 lg:before:block lg:before:h-full lg:before:w-1 lg:before:bg-dark-blue-grey-two lg:before:content-['']"
    >
      <HeaderBlockBreadcrumbs class="absolute mt-3 text-dark-blue" />

      <header class="grid grid-cols-12 gap-4">
        <div
          class="col-span-12 items-center pt-3 md:col-span-8 lg:col-span-8 lg:flex"
        >
          <div class="header-text py-4 lg:py-5">
            <h1>{{ resolution.fields.title }}</h1>
            <TextBadge class="mt-3">
              {{
                resolution.fields.targetCompany
              }}
            </TextBadge>
          </div>
        </div>

        <div class="col-span-12 -ms-3 md:col-span-4 md:ms-0 lg:col-span-4">
          <NuxtImg
            v-if="resolution.fields.image?.fields.file"
            :src="resolution.fields.image.fields.file.url"
            :alt="resolution.fields.image.fields.title"
            :width="1472"
            :height="750"
            fit="contain"
            class="h-full max-h-[750px] min-h-[400px] w-[calc(100%+1.5rem)] max-w-none object-cover lg:w-[calc(100%+3rem)] 2xl:w-[calc((100vw-1360px)/2+100%)]"
          />
        </div>
      </header>
    </Container>

    <Container>
      <div class="article__body grid grid-cols-12 gap-4 py-4 lg:py-6">
        <div class="col-span-12 pt-1 lg:col-span-2">
          <b>Filed:</b>
          <p>
            {{
              resolution.fields.filingDate
                ? formatDate(new Date(String(resolution.fields.filingDate)))
                : "-"
            }}
          </p>

          <b>AGM date:</b>
          <p>
            {{
              resolution.fields.agmDate
                ? formatDate(new Date(String(resolution.fields.agmDate)))
                : "-"
            }}
          </p>

          <b>Status:</b>
          <p>{{ resolution.fields.status || "-" }}</p>

          <b>Issue:</b>
          <p>{{ resolution.fields.issue || "-" }}</p>

          <b>Engagement:</b>
          <p>{{ resolution.fields.engagement || "-" }}</p>

          <b>Lead filer:</b>
          <p>{{ resolution.fields.leadFiler || "-" }}</p>

          <b>Engagement coordinator:</b>
          <p>{{ resolution.fields.engagementCoordinator || "-" }}</p>
        </div>

        <div class="article__content col-span-12 lg:col-span-6">
          <RichTextRenderer
            v-if="resolution.fields.content"
            :document="resolution.fields.content"
            :node-renderers="nodeRenderers"
          />
        </div>
      </div>
    </Container>
  </div>
</template>
