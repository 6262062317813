import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypePageSkeleton } from "./TypePage";

export type TypeShareholderResolutionFields = {
  title: EntryFieldTypes.Symbol;
  slug: EntryFieldTypes.Symbol;
  parent?: EntryFieldTypes.EntryLink<TypePageSkeleton>;
  image?: EntryFieldTypes.AssetLink;
  targetCompany: EntryFieldTypes.Symbol;
  issue?: EntryFieldTypes.Symbol<"Climate Change" | "Corporate Governance" | "Environment" | "Finance" | "Food Justice" | "Health" | "Human Rights & Worker Rights" | "Inclusiveness" | "Lobbying & Political Contributions" | "Militarism / Violence" | "Sustainability" | "Water">;
  engagement?: EntryFieldTypes.Symbol;
  filingDate?: EntryFieldTypes.Date;
  agmDate?: EntryFieldTypes.Date;
  status?: EntryFieldTypes.Symbol<"Challenged" | "Filed" | "Omitted" | "On Proxy" | "Vote" | "Withdrawn - Tactical/Dialogue" | "Withdrawn - Technical" | "Withdrawn for Agreement" | "Withdrawn">;
  leadFiler?: EntryFieldTypes.Symbol;
  engagementCoordinator?: EntryFieldTypes.Symbol;
  content: EntryFieldTypes.RichText;
  metaTitle?: EntryFieldTypes.Symbol;
  metaDescription?: EntryFieldTypes.Symbol;
};

export type TypeShareholderResolutionSkeleton = EntrySkeletonType<TypeShareholderResolutionFields, "shareholderResolution">;
export type TypeShareholderResolution<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeShareholderResolutionSkeleton, Modifiers, Locales>;

export function isTypeShareholderResolution<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeShareholderResolution<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "shareholderResolution";
}
