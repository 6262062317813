<script lang="ts" setup>
import type { Entry } from "contentful";
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import nodeRenderers from "~/lib/node-renderers.js";
import { isTypeArticle, isTypePage, isTypeReport, isTypeShareholderResolution } from "~/types/contentful";
import type {
  TypeArticleSkeleton,
  TypePageSkeleton,
  TypeReportSkeleton,
  TypeShareholderResolutionSkeleton,
} from "~/types/contentful";

const page
  = useLoadedContent<
    Entry<
      TypeArticleSkeleton | TypePageSkeleton | TypeReportSkeleton | TypeShareholderResolutionSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      "en-GB"
    >
  >();
</script>

<template>
  <TemplateArticle
    v-if="page && isTypeArticle(page)"
    :article="page"
  />
  <TemplateReport
    v-else-if="page && isTypeReport(page)"
    :report="page"
  />
  <TemplateShareholderResolution
    v-else-if="page && isTypeShareholderResolution(page)"
    :resolution="page"
  />
  <div
    v-else-if="page && isTypePage(page) && page.fields.content"
    class="page"
  >
    <MembersOnly
      :level="page.fields.access || ['Public']"
      :show-content="true"
      :show-push="true"
    >
      <RichTextRenderer
        :document="page.fields.content"
        :node-renderers="nodeRenderers"
      />
    </MembersOnly>
  </div>
</template>
