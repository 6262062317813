import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";

export type TypeExternalAuthorFields = {
  name: EntryFieldTypes.Symbol;
  url?: EntryFieldTypes.Symbol;
  jobTitle?: EntryFieldTypes.Symbol;
  companyName?: EntryFieldTypes.Symbol;
  email?: EntryFieldTypes.Symbol;
  thumbnail?: EntryFieldTypes.AssetLink;
};

export type TypeExternalAuthorSkeleton = EntrySkeletonType<TypeExternalAuthorFields, "externalAuthor">;
export type TypeExternalAuthor<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeExternalAuthorSkeleton, Modifiers, Locales>;

export function isTypeExternalAuthor<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeExternalAuthor<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "externalAuthor";
}
